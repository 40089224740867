import request from '@/utils/request'

// 新增时段
export function addSiteTime(data) {
  return request({
    url: '/MA_sitetime/add',
    method: 'post',
    params: data
  })
}

// 修改时段
export function updateSiteTime(data) {
  return request({
    url: '/MA_sitetime/update',
    method: 'post',
    params: data
  })
}

// 分页时段
export function queryPageSiteTime(data) {
  return request({
    url: '/MA_sitetime/queryPage',
    method: 'post',
    params: data
  })
}

// 删除时段
export function delSiteTime(data) {
  return request({
    url: '/MA_sitetime/activityDel',
    method: 'get',
    params: data
  })
}
// 详情时段
export function detailSiteTime(data) {
  return request({
    url: '/MA_sitetime/detail',
    method: 'get',
    params: data
  })
}