<!--  -->
<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item label="场地名称">
          <el-select v-model="search.siteId" clearable filterable placeholder="请选择场地">
            <el-option
              v-for="item in sites"
              :key="item.id"
              :label="item.areaname"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="选择社区">
          <el-select
            v-model="search.communityCode"
            clearable
            placeholder="请选择社区"
          >
            <el-option
              v-for="item in communitys"
              :key="item.levelcode"
              :label="item.name"
              :value="item.levelcode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间">
          <el-date-picker
            v-model="search.time"
            type="date"
            placeholder="选择日期"
            clearable
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click="addRow" icon="el-icon-plus">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe>
      <el-table-column prop="areaname" label="场地名称"> </el-table-column>
      <el-table-column prop="start_time" label="开始时间"> </el-table-column>
      <el-table-column prop="end_time" label="结束时间"> </el-table-column>
      <el-table-column label="操作" fixed="right" width="280" align="center">
        <template slot-scope="scope">
          <!-- <el-button @click="lookDetail(scope.row)" type="primary">查看</el-button> -->
          <el-button @click="updateRow(scope.row)" type="warning">修改</el-button>
          <el-button @click="deleteRow(scope.row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="paginationbox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="fenye.pagesizes"
        :page-size="fenye.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="fenye.totalnum"
      >
      </el-pagination>
    </div>

    <el-dialog title="新增时段" :visible.sync="addFormVisible" :before-close="beforeCloseFn">
      <el-form :model="addform" :rules="addrules" ref="addform" label-width="150px">
        <el-form-item label="场地名称" prop="siteId">
          <el-select v-model="addform.siteId" :disabled="operateFlag == 'view'" clearable filterable placeholder="请选择场地">
            <el-option
              v-for="item in sites"
              :key="item.id"
              :label="item.areaname"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="check-range"
          v-for="(checkedDate, index) in addform.checkedDates"
          :label="checkedDate.label"
          :key="checkedDate.key"
          :prop="'checkedDates.' + index + '.value'"
          :rules="{
            required: true, message: '请选择日期范围', trigger: 'blur'
          }"
        >
          <el-date-picker
            v-model="checkedDate.value"
            type="daterange"
            :clearable="false"
            :disabled="operateFlag == 'view'"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerDateOptions"
            @change="dateChange(checkedDate, index)">
          </el-date-picker>
          <el-button v-show="index > 0 || (index == 0 && addform.checkedDates.length > 1)" style="margin-left: 15px;" @click.prevent="removeCheckedDate(checkedDate)" type="danger">删除</el-button>
          <el-button v-if="index == addform.checkedDates.length - 1" type="primary" style="margin-left: 15px;" @click.prevent="addCheckedDate" plain>新增</el-button>
        </el-form-item>
        <el-form-item
          class="check-range"
          v-for="(checkedTime, index) in addform.checkedTimes"
          :label="checkedTime.label"
          :key="checkedTime.key"
          :prop="'checkedTimes.' + index + '.value'"
          :rules="{
            required: true, message: '请选择时间范围', trigger: 'blur'
          }"
        >
          <el-time-picker
            is-range
            v-model="checkedTime.value"
            :disabled="operateFlag == 'view'"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="HH:mm"
            value-format="HH:mm"
            @change="timeChange(checkedTime, index)"
            >
          </el-time-picker>
          <el-button v-show="index > 0 || (index == 0 && addform.checkedTimes.length > 1)" :disabled="operateFlag == 'view'" style="margin-left: 15px;" @click.prevent="removeCheckedTime(checkedTime)" type="danger">删除</el-button>
          <el-button v-if="index == addform.checkedTimes.length - 1" type="primary" :disabled="operateFlag == 'view'" plain style="margin-left: 15px;" @click.prevent="addCheckedTime">新增</el-button>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" v-if="operateFlag != 'view'" @click="submitForm('addform')">确认{{operateFlag == 'add'?'新增':'修改'}}</el-button>
          <el-button v-if="operateFlag == 'add'" @click="resetForm('addform')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="operateTitle" :visible.sync="dialogFormVisible" :before-close="beforeCloseFn2">
      <el-form :model="form" :rules="rules" ref="form" label-width="150px">
        <el-form-item label="场地名称" prop="siteId">
          <el-select v-model="form.siteId" disabled clearable filterable placeholder="请选择场地">
            <el-option
              v-for="item in sites"
              :key="item.id"
              :label="item.areaname"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时段日期" prop="date">
          <el-date-picker
            v-model="form.checkedDate"
            disabled
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="时间范围" prop="times">
          <el-time-picker
            is-range
            v-model="form.times"
            :disabled="operateFlag == 'view'"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="HH:mm"
            value-format="HH:mm"
            @change="updateTime"
          ></el-time-picker>
        </el-form-item>
        <el-form-item>
          <el-button  type="primary" v-if="operateFlag != 'view'" @click="submitUpdateForm('form')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { addSiteTime, updateSiteTime, queryPageSiteTime, delSiteTime, detailSiteTime } from '@/api/siteTime'
import { querySitePage } from "@/api/acitvity/index.js";
import { communityOfUser } from '@/api/system';
export default {
  data () {
    return {
      communitys:[],
      sites: [],
      search: {
        communityCode: '',
        siteId: '',
        time: '',
      },
      currentPage: 1,
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10,
      },
      tableData: [],
      operateFlag: '',
      operateTitle: '',
      addFormVisible: false,
      dialogFormVisible: false,
      addform: {
        siteId: '',
        checkedDates: [{
          label: '日期范围',
          value: '',
          key: 'date',
          checkedStr: ''
        }],
        dates: '',
        times: [null, null],
        checkedTimes: [{
          label: '时间范围',
          value: '',
          key: 'time',
          checkedStr: ''
        }],
      },
      minormax: null,
      rangeDateList:[],
      rangeTimeList:[],
      addrules: {
        siteId: [
          { required: true, message: '请选择场地', trigger: 'blur' }
        ],
      },
      form: {
        id: '',
        siteId: '',
        checkedDate: '',
        startTime: '',
        endTime: '',
        times: [],
      },
      rules: {},
    };
  },

  components: {},

  computed: {
    pickerDateOptions() {
      let that = this;
      return {
        disabledDate(time) {
          let nowData = new Date()
          nowData = new Date(nowData.setDate(nowData.getDate() - 1))
          let disable = time < nowData; //不能选择今天之前的日期
          that.rangeDateList.forEach( item => {
            //不能选择已选择的日期范围
            disable = disable || (time.getTime() < item[1] && time.getTime() > ( item[0] - 60 * 60 * 24 * 1000 ))
          })
          return disable
        },
        // onPick(selectTime){
        //   that.rangeDateList.forEach(item=>{
        //     let minDate=null
        //     let maxDate=null
        //     if(selectTime.minDate){
        //       minDate=selectTime.minDate.getTime()
        //     }
        //     if(selectTime.maxDate){
        //       maxDate=selectTime.maxDate.getTime()
        //     }
        //     if(item[1]>minDate&&item[0]<maxDate){
        //       that.$nextTick(()=>{
        //         that.form.checkedDates[that.minormax].value='';
        //         // that.rangeDateList.splice(that.minormax - 1, 1);
        //       })
        //       this.$message({
        //         message: '时间段选择不可以重合',
        //         type: 'warning',
        //       })
        //       return false
        //     }
        //   })
        // },
      }
    },
  },

  created() {
    this.getCommunityOfUser();
    this.getSitesList();
    this.getList();
  },

  mounted(){},

  methods: {
    //获取当前登录用户的管辖社区
    getCommunityOfUser(){
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res=>{
        if(res.result == 200){
          this.communitys =  res.detail;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //获取场地列表
    getSitesList() {
      querySitePage({
        userid: this.GLOBAL.adminId(),
        pagesize: 99999,
        page: 1,
      }).then(res => {
        if (res.result == 200) {
          let detail = res.detail;
          this.sites = detail.list;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    getList() {
      queryPageSiteTime({
        pageSize: this.fenye.pagesize,
        pageNum: this.currentPage,
        communityCode: this.search.communityCode,
        siteId: this.search.siteId,
        time: this.search.time
      }).then(res => {
        if (res.result == 200) {
          let detail = res.detail;
          this.fenye.totalnum = detail.totalRow;
          this.tableData = detail.list;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //新增时段
    addRow() {
      this.operateFlag = 'add';
      this.operateTitle = '新增时段';
      this.addFormVisible = true;
    },
    updateRow(row) {
      this.operateFlag = 'update';
      this.operateTitle = '编辑时段';
      detailSiteTime({id: row.id}).then(res => {
        if(res.result == 200) {
          let data = res.detail;
          this.form.siteId = data.site_id;
          this.form.id = data.id;
          this.form.checkedDate = data.start_time.substring(0, 10);
          let startTime = data.start_time.substr(-5);
          let endTime = data.end_time.substr(-5);
          this.form.startTime = startTime;
          this.form.endTime = endTime;
          this.form.times = [startTime, endTime];
          this.dialogFormVisible = true;
        }
      })
    },
    lookDetail(row) {
      this.operateFlag = 'view';
      this.operateTitle = '时段信息';
      detailSiteTime({id: row.id}).then(res => {
        if(res.result == 200) {
          let data = res.detail;
          // let checkedDates = [];
          // let dateRanges = res.detail.dates.split(',');
          // // dateRanges
          // let checkedTimes = [];
          this.form.siteId = data.site_id;
          this.dialogFormVisible = true;
        }
      })
    },
    deleteRow(row) {
      this.$confirm('是否确定删除该场地时段?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delSiteTime({id: row.id}).then(res => {
          if (res.result == 200) {
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.onSearch();
          } else {
            this.$message.error(res.description);
          }
        });
      }).catch(() => {})
    },
    dateChange(item, index) {
      if(item.value && item.value.length > 0) {
        this.minormax=index;
        // this.rangeList=[]
        this.addform.checkedDates.forEach(res=>{
          let min=new Date(res.value[0]).getTime()
          let max=new Date(res.value[1]).getTime()
          if(min&&max){
            this.rangeDateList.push([min,max])
          }
        })
        this.addform.checkedDates[index].checkedStr = item.value[0] + '/' + item.value[1];
      } else {
        this.rangeDateList.splice(index, 1);
      }
    },
    timeChange(item, index) {
      if(item.value && item.value.length > 0) {
        let min = item.value[0]
        let max = item.value[1]
        if(min && max){
          this.rangeTimeList.push([min,max]);
        }
        this.addform.checkedTimes[index].checkedStr = item.value[0] + '-' + item.value[1];
      } else {
        this.rangeTimeList.splice(index, 1);
      }
    },
    updateTime(time) {
      this.form.startTime = time[0];
      this.form.endTime = time[1];
    },
    addCheckedDate() {
      this.addform.checkedDates.push({
        label: '',
        value: '',
        key: 'date' + Date.now(),
        checkedStr: ''
      });
    },
    removeCheckedDate(item) {
      var index = this.addform.checkedDates.indexOf(item)
      if (index !== -1) {
        this.addform.checkedDates.splice(index, 1);
        this.rangeDateList.splice(index, 1);
      }
      this.addform.checkedDates[0].label = '日期范围'
    },
    addCheckedTime() {
      this.addform.checkedTimes.push({
        label: '',
        value: '',
        key: 'time' + Date.now(),
        checkedStr: ''
      });
    },
    removeCheckedTime(item) {
      var index = this.addform.checkedTimes.indexOf(item)
      if (index !== -1) {
        this.addform.checkedTimes.splice(index, 1);
        this.rangeTimeList.splice(index, 1);
      }
      this.addform.checkedTimes[0].label = '时间范围'
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.addform =  {
        siteId: '',
        checkedDates: [{
          label: '日期范围',
          value: '',
          key: 'date',
          checkedStr: ''
        }],
        dates: '',
        times: [null, null],
        checkedTimes: [{
          label: '时间范围',
          value: '',
          key: 'time',
          checkedStr: ''
        }],
      };
      this.rangeDateList = [];
      // this.addform.times = [];
      // this.addform.times.push('00:00:00', '23:59:59');
      // this.$set(this.addform,"times", [null, null]);
      // if(this.addform.checkedDates.length > 1) {
      //   this.addform.checkedDates = [{
      //     label: '日期范围',
      //     value: '',
      //     key: 'date',
      //     checkedStr: ''
      //   }];
      // }
      // if(this.addform.checkedTimes.length > 1) {
      //   this.addform.checkedTimes = [{
      //     label: '时间范围',
      //     value: '',
      //     key: 'time',
      //     checkedStr: ''
      //   }];
      // }
    },
    //关闭前重置表单
    beforeCloseFn(done){
      this.resetForm('addform');
      done();
    },
    //关闭前重置表单
    beforeCloseFn2(done){
      this.resetForm2('form');
      done();
    },
    resetForm2(formName) {
      this.$refs[formName].resetFields();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let datesArr = [];
          let timesArr = [];
          this.addform.checkedDates.map(item => {
            datesArr.push(item.checkedStr);
          });
          this.addform.checkedTimes.map(item => {
            timesArr.push(item.checkedStr);
          });
          let data = {
            siteId: this.addform.siteId,
            dates: datesArr.join(','),
            times: timesArr.join(','),
          }
          if(this.operateFlag == 'add') {
            addSiteTime(data).then(res => {
              if(res.result == 200){
                this.resetForm('addform');
                this.addFormVisible = false;
                this.onSearch();
              } else {
                this.$message.error(res.description);
              }
            })
          }
        }
      })
    },
    submitUpdateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            id: this.form.id,
            startTime: this.form.startTime,
            endTime: this.form.endTime,
          };
          updateSiteTime(data).then(res => {
            if(res.result == 200){
              this.resetForm('form');
              this.dialogFormVisible = false;
              this.onSearch();
            } else {
              this.$message.error(res.description);
            }
          })
        }
      })
    },
    //切换页面条数
    handleSizeChange(val) {
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
  }
}

</script>
<style lang='scss' scoped>
.searchbox {
  margin: 0 10px;
}
.paginationbox {
  text-align: right;
  margin-top: 20px;
}
.check-range {
  ::v-deep {
    .el-form-item__content {
      display: flex;
    }
  }
}
</style>